<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="1000px"
      max-height="800px"
      scrollable
      persistent
    >
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>

      <v-card background-color="#f2f2f2" v-else>
        <v-card-title
          class="py-0 pt-7"
          style="background-color: #7253cf; color: white"
        >
          <div class="d-flex px-1">
            <img src="@/assets/teamModalLogo.svg" height="85" width="100" />
          </div>
          <div class="ml-1 dialogTitle-text">Graph</div>
          <v-spacer></v-spacer>
          <v-icon
            text
            large
            color="white"
            style="
              cursor: pointer;
              position: relative;
              right: -15px;
              bottom: 40px;
            "
            @click="toggleGameGraphModal({ show: false })"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pt-5">
          <div class="graph-container">
            <canvas ref="lineChart" style="height: 400px"></canvas>
          </div>
          <!-- <graph-modal></graph-modal> -->
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Chart from "chart.js";
import { GAME_GRAPH_API } from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
export default {
  name: "GameGraphModal",
  components: {
    //GraphModal: () => import("../GraphModal/GraphModal"),
  },
  data() {
    return {
      loading: false,
      colorMap: {}, // To store colors for each game date
      labels: [],
    };
  },
  computed: {
    ...mapGetters({
      showGetters: "gameManagement/getGameGraphShow",
      selectGameData: "gameManagement/getGame",
    }),
    show: {
      get() {
        return this.showGetters;
      },
      set(value) {
        return this.toggleGameGraphModal({ show: value });
      },
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else {
        this.closeModal();
      }
    },
  },
  methods: {
    ...mapActions({
      toggleGameGraphModal: "gameManagement/toggleGameGraphModal",
      showToast: "snackBar/showToast",
    }),

    fetchGraphData() {
      // Simulate API response
      const responseData = {
        success: true,
        message: "success",
        labels: [
          "days_45",
          "days_30",
          "days_21",
          "days_14",
          "days_10",
          "days_07",
          "days_03",
          "days_01",
          "game_day",
          "final",
        ],
        graph_data: [
          {
            game_date: "2024-03-16",
            data: [0, 155, 185, 208, 316, 367, 482, 547, 575, 575],
          },
          {
            game_date: "2024-03-16",
            data: [0, 99, 102, 114, 180, 211, 288, 355, 371, 387],
          },
        ],
      };

      // Extracting labels and data from the response
      const labels = responseData.labels;
      const graphData = responseData.graph_data.map((item) => {
        return {
          label: item.game_date,
          data: item.data,
        };
      });

      // Rendering the line chart
      this.renderLineChart(labels, graphData);
    },

    getOverViewDonationData() {
      const successHandler = (res) => {
        this.loaded = true;
        const responseData = res.data;
        // this.graphgameid=res.data.graph_data[0].id;
        const labels = responseData.labels.map((label) => {
          return label
            .replace("days_", " ")
            .replace("final", "online close")
            .replace("door_sale", "final");
        });

        const graphData = responseData.graph_data.flatMap((item) => {
          const color = this.getColorForGameDate(item.game_date);
          let result = [
            {
              label: "Game On " + item.game_date,
              data: item.data,
              color: color,
            },
          ];
          if (item.assembly_info) {
            const { assembly_date, label_index } = item.assembly_info;
            labels[
              label_index
            ] = `${labels[label_index]} (Assembly: ${assembly_date})`;
            result.push({
              label: `Assembly on ${assembly_date}`,
              data: item.data, // Adjust data accordingly if it's different for the assembly
              color: color,
            });
          }
          return result;
        });

        // Rendering the line chart
        this.renderLineChart(labels, graphData);
      };

      const failureHandler = (res) => {
        console.log(res);
      };

      let formData = {};
      formData["game_id"] = this.$route.query.game_id;
      Axios.request_GET(
        GAME_GRAPH_API,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true
      );
    },

    renderLineChart(labels, graphData) {
      const ctx = this.$refs.lineChart.getContext("2d");

      new Chart(ctx, {
        type: "line",
        data: {
          labels: labels,
          datasets: graphData.map((data) => {
            return {
              label: data.label,
              data: data.data,
              fill: false,
              borderColor: data.color,
              borderWidth: 2,
            };
          }),
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Days",
                  fontSize: 14, // Set font size here
                  fontStyle: "bold", // Set font style to bold here
                },
                ticks: {
                  maxRotation: 0, // This ensures labels are not slanted
                  minRotation: 0,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    },
    getColorForGameDate(gameDate) {
      // Check if the color for this game date is already assigned
      if (!this.colorMap[gameDate]) {
        // Assign a new random color and store it in the colorMap
        this.colorMap[gameDate] = getRandomColor();
      }
      return this.colorMap[gameDate];
    },
    openModal() {
      this.getOverViewDonationData();
    },
    closeModal() {
      this.loading = false;
    },
  },
  mounted() {
    //this.getOverViewDonationData();
  },
};
// Function to generate random color
function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
</script>
<style scoped>
/* Add any custom styles here */
.ticketTextHeading {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 140%;
  /* identical to box height, or 45px */

  color: #2c1963;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.table-header {
  background: #e7e5ed;
  font-weight: bold;
}

.date-cell {
  padding: 10px;
  background-color: #f9f9f9;
  font-size: 14px;
}

.date-info {
  margin-bottom: 5px;
}

.date-label {
  font-weight: bold;
  font-size: 13px;
  color: #6c757d;
}

.date-value {
  display: inline-block;
  margin-left: 5px;
  color: #333;
}

.value-cell {
  padding: 10px;
  font-size: 16px;
  background-color: #ffffff;
}

.elevation-8 {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.textHeader {
  padding: 10px;
  font-size: 16px;
  color: #333;
}

.v-dialog__content.v-dialog {
  transform-origin: center center;
  max-width: 1000px !important;
}

@media (max-width: 768px) {
  .graph-container {
    width: 100%; /* Full width for mobile */
    height: 400px; /* Reduced height for smaller screens */
  }
}
</style>
